import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class ContactService
{

  constructor(private api: ApiService) {}

  getContact(): Observable<any>
  {
    return this.api.get('md.php?page=contact&type=pages');
  }
}
