import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-content-jobs-link03',
  templateUrl: './content-jobs-link03.component.html',
  styleUrls: ['./content-jobs-link03.component.scss']
})
export class ContentJobsLink03Component implements OnInit {
  @Input() data;

  constructor() { }

  ngOnInit() {
  }

}
