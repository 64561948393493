<section class="legal" *ngIf="data">
  <header class="legal__heading">
    <h1 class="legal__heading__title legal__heading__inner">{{ data?.content?.title }}</h1>
  </header>

  <article class="legal__content">
    <p class="legal__content__text">
      {{ data?.content?.text }}
    </p>

    <section class="report" *ngFor="let file of files">
      <article class="report__content">
        <div class="report__content__title">{{ file?.title }}</div>
        <div class="report__content__ref">{{ file?.date }}</div>
      </article>
      <a href="{{ file?.file }}" class="report__icon" target="_blank" title="Report File" rel="noopener noreferrer">
        <img src="{{ file?.doc_img }}" class="report__icon__img" alt="Doc icon">
        <span class="report__icon__text">Download file</span>
      </a>
    </section>
  </article>



</section>

