<section class="legal" *ngIf="data">
  
  <header class="legal__heading">
      <h1 class="legal__heading__inner legal__heading__title">{{ data?.header?.title }}</h1>
  </header>

  <article class="legal__content">
    <section class="report" *ngFor="let report of reports">
        <article class="report__content" *ngIf="report?.title">
          <div class="report__content__title">{{ report?.title }}</div>
          <div class="report__content__ref">{{ report?.subtitle }}</div>
        </article>
        <a *ngIf="report?.file" href="{{ report?.file }}" class="report__icon" target="_blank" title="Report File" rel="noopener noreferrer">
          <img src="{{ report?.doc_img }}" class="report__icon__img" alt="Doc icon">
          <span class="report__icon__text">{{ report?.download_text }}</span>
        </a>
    </section>
  </article>

</section>
