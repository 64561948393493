import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NewsService
{

  constructor(private api: ApiService) {}

  getNews(): Observable<any>
  {
    return this.api.get('md.php?type=news');
  }

  getNew(slug): Observable<any>
  {
    return this.api.get('md.php?page=' + slug + '&type=pages');
  }

  getAllNews(): Observable<any>
  {
    return this.api.get('md.php?type=all-news');
  }

}
