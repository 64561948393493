import { Injectable } from '@angular/core';
import {ApiService} from '../api/api.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CovidService
{

  constructor(private api: ApiService) {}

  getItems(page = ''): Observable<any>
  {
    return this.api.get('md.php?page=' + page + '&type=covid19');
  }

}
