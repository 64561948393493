<ng-template #content let-modal>
    <button type="button" class="close" name="button_close_modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>

    <h2 class="modal__title">Digital Marketing Specialist</h2>
    <p class="modal__location">
      <img src="../../../assets/icons/location-icon.png" class="modal__location__img" alt="Icon Location">
      Madrid, Spain
    </p>

    <div class="modal__text">
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi ipsum magna, condimentum sagittis nisi sit amet, cursus porta dui. Nam quam nulla, malesuada id arcu ut, finibus posuere est. Vestibulum quis ultricies diam, iaculis aliquet ante. Duis suscipit odio suscipit ultrices ornare. Donec auctor neque in lorem mollis dictum. Cras commodo sapien ac auctor vestibulum. Sed sollicitudin ullamcorper ultricies. Donec volutpat, libero vel malesuada venenatis, risus orci efficitur lacus, non molestie erat nisi eget lorem. Morbi aliquet velit nec dui pulvinar cursus. Quisque feugiat eu eros posuere auctor. Proin in urna viverra sapien mattis efficitur quis vulputate urna. Pellentesque pretium blandit scelerisque. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent at libero congue, tincidunt enim id, tincidunt nisi. Etiam in libero nec felis sollicitudin sodales eu sit amet justo. In luctus nisl vel felis facilisis, ac iaculis lectus feugiat.
      </p>

      <p>
        <strong>Requirements:</strong>
      </p>

      <ul>
        <li>Sed sollicitudin ullamcorper ultricies. </li>
        <li>Donec volutpat, libero vel malesuada venenatis, risus orci efficitur lacus. </li>
        <li>Non molestie erat nisi eget lorem. Morbi aliquet velit nec dui pulvinar cursus. </li>
        <li>Quisque feugiat eu eros posuere auctor. </li>
        <li>Proin in urna viverra sapien mattis efficitur quis vulputate urna. </li>
      </ul>

      <h3 class="modal__subtitle">Apply now</h3>

      <p>Send us an email with your CV to <a href="mailto:careers@cnpsantander.com" class="link" rel="noopener noreferrer"><span class="link__text" [innerHTML]="'careers@cnpsantander.com'" ></span></a></p>

    </div>
  
</ng-template>

<hr>

<pre>{{closeResult}}</pre>
