<section id="contact-{{ data?.country }}" class="map__content">
    <p class="map__content__country d-none">{{ data?.country }}</p>

    <div class="map__content__list">
        <div class="map__content__list__item"></div>
        <article *ngFor="let product of products">
            <div class="map__content__list__item" *ngIf="product?.product_title">
                <div class="map__content__list__item__left">
                    <p class="map__content__list__item__title">
                        {{ product?.name }}
                    </p>
                </div>
                <div class="map__content__list__item__right">
                    <p *ngIf="product?.phone?.number" class="map__content__list__item__tel">
                        <a href="tel:{{ product?.phone?.number }}" title="Phone Number" rel="noopener noreferrer">{{ product?.phone?.number }}</a>
                        <br />
                        <span [innerHTML]="product?.phone?.opening_hours"></span>
                    </p>
                    <a href="mailto:{{ product?.email?.text }}" class="map__content__list__item__mail" title="Send Mail" rel="noopener noreferrer">
                        {{ product?.email?.text }}
                    </a>
                    <div *ngIf="product?.title || product?.address?.text" class="map__content__list__item__address">
                        <div *ngIf="product?.title" [innerHTML]="product?.title"></div>
                        <div *ngIf="product?.address?.text" [innerHTML]="product?.address?.text"></div>
                    </div>
                    <p *ngIf="product?.website">
                        <a href="{{ product?.website?.text }}" class="map__content__list__item__web" title="Website" target="_blank" rel="noopener noreferrer">
                            {{ product?.website?.text }}
                        </a>
                    </p>
                    <p class="map__content__list__item__link" *ngIf="product?.enquiries_url">
                        <a href="{{ product?.enquiries_url }}" class="link" title="Enquiries Url" rel="noopener noreferrer"><span class="link__text">Sales Enquiries</span></a>
                    </p>
                    <p *ngIf="product?.online_claim_site" class="map__content__list__item__link">
                        <a href="{{ product?.online_claim_site }}" class="link" title="Online Claim Site" rel="noopener noreferrer"><span class="link__text">Online Claim Site</span></a>
                    </p>
                </div>
            </div>
        </article>
    </div>
</section>
