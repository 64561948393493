import { Component, OnInit } from '@angular/core';
import { ReportService } from '../../services/report/report.service';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit
{

  public data;
  public reports;

  constructor(
      private reportService: ReportService,
      private meta: Meta,
      private titleService: Title
  ) { }

  ngOnInit()
  {
    this.reportService.getReports().subscribe(reports => {
      this.data = (reports.length > 0) ? reports[0].acf : reports.acf;
      if (this.data.hasOwnProperty('meta_tag_title')) {
        this.titleService.setTitle(this.data.meta_tag_title.content ?? 'Reports');
      }
      if (this.data.hasOwnProperty('meta_tag_description')) {
        this.meta.updateTag({ name: 'description', content: this.data.meta_tag_description.content });
      }
      const obj = this.data.content;
      this.reports = Object.keys(obj).map(e => obj[e]);
    });
  }

}
