import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { ContactService } from '../../services/contact/contact.service';
import { ActivatedRoute } from '@angular/router';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
import { HelperProvider } from '../../providers/helper.provider';

declare var google;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit, AfterViewInit
{
  @ViewChild(MapInfoWindow, {static: false}) infoWindow: MapInfoWindow;

  public data;
  public locationsOriginal;
  public zoom = 3;

  public countryNameLowerCase = '';
  currentLocation: any;
  center: any;
  markerOptions: any;
  markerPositions: any = [];

  mapOptions: any = {
    fullscreenControl: false,
    disableDefaultUI: true,
    disableDoubleClickZoom: true,
    draggable: false,
    styles: null
  };

  infoContent: any;


  staticMapParams: string = '';
  staticMapApiKey: string = 'AIzaSyB-a4qE-EjW3aYnwlZVtwgS64aOkdv_0Qs';


  constructor(
      private contactService: ContactService,
      private elRef: ElementRef,
      private route: ActivatedRoute,
      private meta: Meta,
      private titleService: Title,
      public helperProvider: HelperProvider
  ) {

    this.mapOptions.styles = [
      {
        elementType: 'geometry',
        stylers: [
          {
            color: '#f5f5f5'
          }
        ]
      },
      {
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off'
          }
        ]
      },
      {
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#616161'
          }
        ]
      },
      {
        elementType: 'labels.text.stroke',
        stylers: [
          {
            color: '#f5f5f5'
          }
        ]
      },
      {
        featureType: 'administrative.land_parcel',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#bdbdbd'
          }
        ]
      },
      {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
          {
            color: '#eeeeee'
          }
        ]
      },
      {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#757575'
          }
        ]
      },
      {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
          {
            color: '#e5e5e5'
          }
        ]
      },
      {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9e9e9e'
          }
        ]
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [
          {
            color: '#ffffff'
          }
        ]
      },
      {
        featureType: 'road.arterial',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#757575'
          }
        ]
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [
          {
            color: '#dadada'
          }
        ]
      },
      {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#616161'
          }
        ]
      },
      {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9e9e9e'
          }
        ]
      },
      {
        featureType: 'transit.line',
        elementType: 'geometry',
        stylers: [
          {
            color: '#e5e5e5'
          }
        ]
      },
      {
        featureType: 'transit.station',
        elementType: 'geometry',
        stylers: [
          {
            color: '#eeeeee'
          }
        ]
      },
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
          {
            color: '#c9c9c9'
          }
        ]
      },
      {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9e9e9e'
          }
        ]
      }
    ];

    const self = this;
    setTimeout(() => {
      this.helperProvider.loadGoogleMaps().then(() => {
        self.center = google.maps.LatLngLiteral = {lat: 45.4494803, lng: 2.0737193};
        self.getContact();
      });
    }, 600);

    const markers = '&markers=anchor:32,10%7Cicon:https://goo.gl/5y3S82%7CCanberra+ACT';

    this.staticMapParams = 'key=' + this.staticMapApiKey + '&center=46.4373991,-0.8470713&size=2048x1024&scale=2';
    this.staticMapParams += '&maptype=terrain';
    this.staticMapParams += '&zoom=' + this.zoom;
  }

  ngOnInit() {

  }

  ngAfterViewInit()
  {
    document.addEventListener('currentLinkCountryMoreInfo', (e) => {
      const country = e['detail'].country;

      const contents = document.querySelectorAll('.map__content');
      if (contents) {
        for (let i = 0, len = contents.length; i < len; i++) {
          contents[i].classList.add('d-none');
        }
      }

      const infoContent = document.querySelector('#contact-country');

      if (infoContent) {
        infoContent.classList.remove('d-none');
      }

      // tslint:disable-next-line:forin
      for (const i in this.locationsOriginal) {
        const location = this.locationsOriginal[i];
        if (location.country === country) {
          this.currentLocation = location;
          break;
        }
      }

      this.openProductsInfo(null, null);
    });
  }

  getContact()
  {
    this.contactService.getContact().subscribe(contact => {
      this.data = (contact.length > 0) ? contact[0].acf : contact.acf;
      if (this.data.hasOwnProperty('meta_tag_title')) {
        this.titleService.setTitle(this.data.meta_tag_title.content ?? 'Contact');
      }
      if (this.data.hasOwnProperty('meta_tag_description')) {
        this.meta.updateTag({ name: 'description', content: this.data.meta_tag_description.content });
      }
      const obj = this.data.header.map_location;

      this.locationsOriginal = Object.keys(obj).map(e => obj[e]);
      this.markerPositions = [];

      this.markerOptions = google.maps.MarkerOptions = {draggable: false};

      this.initMapMarkers(this.locationsOriginal);
      this.route.queryParams
          .subscribe(content => {
            if ( Object.keys(content).length > 0 ) {
              setTimeout(() => {
                this.openInfoWindow(null, content);
                this.openProductsInfo(content, null);
              }, 1000);
            }
          });
    });
  }

  initMapMarkers(locations: any)
  {
    const data = [];
    // tslint:disable-next-line:forin
    for(const i  in locations) {
      data.push({
        cordX: locations[i].cordx,
        cordY: locations[i].cordy,
        icon: this.selectMapMarkerIcon(locations[i].country),
        modal: {
          show: false,
          title: locations[i].country,
          content: locations[i].city,
          key: locations[i].country.toLowerCase()
        }
      });
    }

    setTimeout(() => {
      this.helperProvider.loadMapMarkersScript().then(() => {
        this.helperProvider.buildMapMarkers(data);
      });
    }, 2000);
  }

  openInfoWindow(marker: MapMarker, location: any)
  {
    if (this.countryNameLowerCase !== location.country.toLowerCase()) {
      this.infoContent = location;
      this.infoWindow.open(marker);
    }

    const contents = document.querySelectorAll('.map__content');
    if (contents) {
      for (let i = 0, len = contents.length; i < len; i++) {
        contents[i].classList.add('d-none');
      }
    }

    const infoContent = document.querySelector('#contact-country');

    if (infoContent) {
      infoContent.classList.remove('d-none');
    }

    this.currentLocation = location;
    return false;
  }

  selectMapMarkerIcon(country: string)
  {
    if (country) {
      if (this.countryNameLowerCase === country.toLowerCase()) {
        return '/assets/icons/map-marker-active-icon.svg';
      }
    }

    return '/assets/icons/map-marker-icon.svg';
  }

  openProductsInfo(content, event)
  {
    if (event) {
      event.preventDefault();
    }
    this.scrollTo('contact-country');
  }

  closedWindowHandler(ev)
  {
    this.scrollTo('header');
  }


  scroll(el: HTMLElement)
  {
    el.scrollIntoView();
  }

  scrollTo(id)
  {
    const el = document.getElementById(id);
    if (el) {
      let actualScroll = window.pageYOffset;
      const scrollInterval = setInterval(() => {
        if ( actualScroll >= el.offsetTop - 120 ) {
          clearInterval(scrollInterval);
        } else {
          actualScroll += 10;
          window.scrollTo(0, actualScroll);
        }
      }, 10);
    }
  }
}
