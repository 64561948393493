import { Injectable } from '@angular/core';
import {ApiService} from '../api/api.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportService
{
  constructor(private api: ApiService) {}

  getReports(): Observable<any>
  {
    return this.api.get('md.php?page=reports&type=pages');
  }
}
