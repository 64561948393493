<section class="hero">
    <section class="hero__bg" style="background-image:url('../assets/images/hero-home.jpg');"></section>
    <section class="hero__content">
        <article class="hero__content__text" id="hero-content">
            <!-- Contrast Failure ↓ -->
            <p class="red">CNP Santander Insurance</p>
            <p class="black">Insuring all our futures</p>
            <p>
                <strong>
                    <!-- Contrast Failure ↓ -->
                    <em class="red">{{ data?.header?.text?.red_part_1 }}</em>
                    <span>&nbsp;</span>
                    <span>{{ data?.header?.text?.part_2 }}</span>
                </strong>
            </p>
            <p>
                {{ data?.header?.text?.part_3 }}
            </p>
        </article>
        <a [routerLink]="['/aboutus']" class="btn btn--red" title="About Us" rel="noopener noreferrer">
            <span>{{ data?.header?.text?.button_text }}</span>
            <figure class="btn__asset">
                <img src="../assets/icons/right-white-icon.svg"  alt="Icon right">
            </figure>
        </a>
        <a href="#" class="hero__content__down" (click)="scrollTo('hero-content')" title="Scroll to Hero Content" rel="noopener noreferrer">
            <figure class="hero__content__down__img">
                <img src="../assets/icons/down-white-icon.svg"  alt="Hero content image">
            </figure>
        </a>
    </section>
</section>
<section class="whatwedo">
    <section class="whatwedo__inner">
        <header class="whatwedo__inner__header">
            <h1 class="whatwedo__inner__header__title">{{ data?.content?.products_box?.title }}</h1>
            <article class="whatwedo__inner__header__text">
                <p>
                    <strong>
                        {{ data?.content?.products_box?.text?.bold_part_1 }}
                    </strong>
                </p>
                <p>
                    {{ data?.content?.products_box?.text?.part_2 }}
                </p>
            </article>
        </header>

        <section class="whatwedo__inner__articles" *ngIf="products?.length > 0">
            <article class="whatwedo__article" *ngFor="let item of products">
                <div class="whatwedo__article__image">
                    <img src="{{ item?.BACKGROUND_IMG_URL }}" class="whatwedo__article__image__img" alt="What we do Image">
                </div>
                <div class="whatwedo__article__content">
                    <p class="whatwedo__article__content__title">{{ item?.TITLE_BOLD_PART_1 }}</p>
                    <p class="whatwedo__article__content__text">{{ item?.TITLE_PART_2 }}</p>
                    <a [routerLink]="['/products/',  item?.SLUG ]" class="link" title="Go To Product" rel="noopener noreferrer">
                        <!-- Contrast Failure ↓ -->
                        <span class="link__text">Learn more</span>
                    </a>
                </div>
            </article>
        </section>
    </section>
</section>
<section class="join">
    <section class="join__inner">
        <a [routerLink]="['/careershome']" class="join__inner__title" title="Go To Careers" rel="noopener noreferrer">
            <span *ngIf="data?.content?.careers_box?.title?.part_1">{{ data?.content?.careers_box?.title?.part_1 }}</span>
            <span>&nbsp;</span>
            <span *ngIf="data?.content?.careers_box?.title?.bold_part_2">{{ data?.content?.careers_box?.title?.bold_part_2 }}</span>
            <img class="join__inner__title__asset" src="../assets/icons/arrow-right-icon.svg" alt="Icon arrow right">
        </a>
        <article class="join__inner__text">
            <p>
                <span>{{ data?.content?.careers_box?.text?.part_1 }} </span>
                <span>&nbsp;</span>
                <strong>{{ data?.content?.careers_box?.text?.bold_part_2 }}</strong>
                <span>&nbsp;</span>
                <span>{{ data?.content?.careers_box?.text?.part_3 }}</span>
            </p>
        </article>
    </section>
</section>
<section class="corporate-news">
    <section class="corporate-news__inner">
        <a [routerLink]="['/news']" class="corporate-news__inner__title" title="Go To News" rel="noopener noreferrer">
            <span *ngIf="data?.content?.news_box?.title">{{ data?.content?.news_box?.title }}</span>
            <img class="join__inner__title__asset" src="../assets/icons/arrow-right-icon.svg" alt="Icon arrow right">
        </a>
        <section class="corporate-news__inner__wrapper">
            <article class="news-card" *ngFor="let n of news">
                <div class="news-card__image">
                    <figure *ngIf="n?.IMAGE_URL">
                        <img class="news-card__image__img" src="{{ n?.IMAGE_URL }}" alt="News card image">
                    </figure>
                </div>
                <div class="news-card__content">
                    <p *ngIf="n?.TITLE" class="news-card__content__title">{{ n?.TITLE }}</p>
                    <a [routerLink]="['/corporate-news/',  n?.SLUG ]" class="link" title="Go To News" rel="noopener noreferrer">
                        <span class="link__text">{{ n?.LINK_TEXT }}</span>
                    </a>
                </div>
            </article>
        </section>
    </section>
</section>
<section class="contact">
    <section class="contact__inner">
        <article class="contact__inner__content">
            <p *ngIf="data?.content?.contact_us_box?.title" class="contact__inner__content__title">
                {{ data?.content?.contact_us_box?.title }}
            </p>
            <div *ngIf="data?.content?.contact_us_box?.text" class="contact__inner__content__text">
                <p>
                    {{ data?.content?.contact_us_box?.text }}
                </p>
            </div>
        </article>
        <ul class="contact__inner__list" *ngIf="countries?.length > 0">
            <li class="contact__inner__list__item" *ngFor="let country of countries">
                <a [routerLink]="['/contact']" [queryParams]="{country: country}" title="Go To Country" rel="noopener noreferrer">{{ country }}</a>
            </li>
        </ul>
    </section>
</section>
