<section class="legal" *ngIf="data">
  <header class="legal__heading">
    <h1 class="legal__heading__title legal__heading__inner">{{ data?.header?.title }}</h1>
  </header>

  <article class="legal__content">
    <p class="legal__content__text">
      {{ data?.content?.legal_text?.part_1 }}
    </p>
    <p class="legal__content__text">
        {{ data?.content?.legal_text?.part_2 }}
    </p>
    <p class="legal__content__text">
        {{ data?.content?.legal_text?.part_3 }}
    </p>
    <p class="legal__content__text">
        {{ data?.content?.legal_text?.part_4 }}
    </p>
    <h2 class="legal__content__title">{{ data?.content?.regulatory_title }}</h2>
    <p class="legal__content__text">
      {{ data?.content?.regulatory_title?.part_1 }}
    </p>
    <p class="legal__content__text">
      {{ data?.content?.regulatory_title?.part_2 }}
    </p>
    <p class="legal__content__text">
      {{ data?.content?.regulatory_title?.part_3 }}
    </p>
    <p class="legal__content__text">
      {{ data?.content?.regulatory_title?.part_4 }}
    </p>
    <p class="legal__content__text">
      {{ data?.content?.regulatory_title?.part_5 }}
    </p>
  </article>
</section>
