<!-- Video Vimeo Block -->
<section *ngIf="data?.show_block_video" class="video video--right-top">
  <div class="video__inner">
    <article class="video__content">
      <div *ngIf="data?.content?.text?.intro_1" class="video__content__intro_1" [innerHTML]="data?.content?.text?.intro_1"></div>
      <div *ngIf="data?.content?.text?.intro_2" [innerHTML]="data?.content?.text?.intro_2"></div>
    </article>
    <div id="video_content" class="video__image"> </div>
  </div>
  <div class="video__footer" *ngIf="data?.content?.text?.intro_3" [innerHTML]="data?.content?.text?.intro_3"></div>
</section>
<!-- End Video Vimeo Block -->
