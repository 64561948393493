import { Component, OnInit} from '@angular/core';
import { ProductService } from '../../services/product/product.service';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-products-list',
    templateUrl: './products-list.component.html',
    styleUrls: ['./products-list.component.scss']
})
export class ProductsListComponent implements OnInit
{

    public data;
    public productList;
    public productTitleList;

    constructor(
        private productsService: ProductService,
        private meta: Meta,
        private titleService: Title
    )
    {}

    ngOnInit()
    {
        this.productsService.getProducts('products01', '&type=pages').subscribe(news => {
            this.data = (news.length > 0) ? news[0].acf : news.acf;

            this.productList = JSON.parse(this.data.products_list);
            this.productTitleList = JSON.parse(this.data.products_title_list);

            if (this.data.hasOwnProperty('meta_tag_title')) {
                this.titleService.setTitle(this.data.meta_tag_title.content ?? 'Products');
            }
            if (this.data.hasOwnProperty('meta_tag_description')) {
                this.meta.updateTag({ name: 'description', content: this.data.meta_tag_description.content });
            }
        });
    }


    scrollTo(id, event)
    {
        if (event) {
            event.preventDefault();
        }
        const el = document.getElementById(id);
        let actualScroll = window.pageYOffset;
        const scrollInterval = setInterval(() => {
          if( actualScroll >= el.offsetTop - 120 ) {
            clearInterval(scrollInterval);
          } else {
            actualScroll += 30;
            window.scrollTo(0, actualScroll);
          }
        }, 10);
    }
}
