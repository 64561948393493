import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-article-component',
  templateUrl: './article-component.component.html',
  styleUrls: ['./article-component.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ArticleComponentComponent implements OnInit, OnChanges
{
  @Input() data;
  @Input() slugActual;
  current;
  newsList;
  prevId = 2;
  prevNew = false;
  nextId = 5;
  nextNew = false;

  constructor(
      public domSanitizer: DomSanitizer,
      public ref: ChangeDetectorRef
  ) { }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges)
  {
    if (changes.data) {
      setTimeout(() => {
        this.data = changes.data.currentValue;
        if (this.data) {
          this.calcPrevNext();
        }
      }, 100);
    }
  }

  calcPrevNext()
  {
    let currentIndex = null;
    this.current = this.data.find(newInfo => newInfo.slug === this.slugActual);
    this.prevNew = false;
    this.nextNew = false;
    this.newsList = this.data;

    this.newsList.map((item, index) => {
      if (item.slug === this.slugActual) {
        currentIndex = index;
      }
      return item;
    });

    const idActual = currentIndex;

    if (this.current && this.current.hasOwnProperty('acf')) {
      const acf = { ...this.current.acf.content, has_next: this.current.acf.has_next};
      delete this.current.acf;
      this.current = {...this.current, ...acf};
    }

    if (this.current && this.current.has_next === true ) {
      this.nextId = idActual + 1;
      this.data.map((item, index) => {
        if (this.nextId === index) {
          this.nextNew = item;
        }
        return item;
      });
    }

    if (idActual >= 0 ) {
      this.prevId = idActual - 1;
      this.data.map((item, index) => {
        if (this.prevId === index) {
          this.prevNew = item;
        }
        return item;
      });
    }

    this.newsList = this.data.filter((item, index) => item.slug !== this.slugActual);
    this.newsList = this.newsList.filter((item, index) => index < 2);

    this.ref.detectChanges();
  }


  sanitizerHtml(html: any)
  {
    return this.domSanitizer.bypassSecurityTrustHtml(html);
  }
}
