import { Component, OnInit } from '@angular/core';
import { ProductService } from '../../services/product/product.service';
import { ActivatedRoute } from '@angular/router';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-product',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit
{

    public data;
    public itemList;
    public id;

    constructor(
        private productsService: ProductService,
        private route: ActivatedRoute,
        private meta: Meta,
        private titleService: Title
    ) {}

    ngOnInit()
    {
        this.route.params.subscribe(params => {
                this.id = params['id'];
                this.productsService.getProducts(this.id, '&type=products').subscribe((products: any) => {
                    this.data = (products.length > 0) ? products[0].acf : products.acf;
                    if (this.data.hasOwnProperty('meta_tag_title')) {
                        this.titleService.setTitle(this.data.meta_tag_title.content ?? 'Product');
                    }
                    if (this.data.hasOwnProperty('meta_tag_description')) {
                        this.meta.updateTag({ name: 'description', content: this.data.meta_tag_description.content });
                    }
                    const obj = this.data.content.items_list;
                    this.itemList = Object.keys(obj).map(e => obj[e]);
                });
            }
        );
    }

}
