import { Component, OnInit } from '@angular/core';
import { LegalService } from '../../services/legal/legal.service';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-privacy-policies',
  templateUrl: './privacy-policies.component.html',
  styleUrls: ['./privacy-policies.component.scss']
})
export class PrivacyPoliciesComponent implements OnInit {
  public data;
  public files;

  constructor(
      private legalService: LegalService,
      private meta: Meta,
      private titleService: Title
  ) {}

    ngOnInit()
    {
        this.legalService.getPrivacyPolicies().subscribe(legal => {
            this.data = (legal.length > 0) ? legal[0].acf : legal.acf;
            if (this.data.hasOwnProperty('meta_tag_title')) {
                this.titleService.setTitle(this.data.meta_tag_title.content ?? 'Privacy Policies');
            }
            if (this.data.hasOwnProperty('meta_tag_description')) {
                this.meta.updateTag({ name: 'description', content: this.data.meta_tag_description.content });
            }
            const obj = this.data.content.files;
            this.files = Object.keys(obj).map(e => obj[e]);
        });
    }
}
