import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { GoogleMapsModule } from '@angular/google-maps';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { AboutUsComponent } from './views/about-us/about-us.component';
import { ContactComponent } from './views/contact/contact.component';
import { AboutUs02Component } from './views/about-us02/about-us02.component';
import { AboutUs03Component } from './views/about-us03/about-us03.component';
import { AboutUs04Component } from './views/about-us04/about-us04.component';
import { OurValuesComponent } from './components/our-values/our-values.component';
import { GreatPlaceToWorkComponent } from './components/great-place-to-work/great-place-to-work.component';
import { ProductsOfferComponent } from './components/products-offer/products-offer.component';
import { Content01Component } from './components/content01/content01.component';
import { Content02Component } from './components/content02/content02.component';
import { Content03Component } from './components/content03/content03.component';
import { ContentJobsLinkComponent } from './components/content-jobs-link/content-jobs-link.component';
import { Content04Component } from './components/content04/content04.component';
import { ContentJobsLink02Component } from './components/content-jobs-link02/content-jobs-link02.component';
import { Content05Component } from './components/content05/content05.component';
import { ArticlesTwoSidesComponent } from './components/articles-two-sides/articles-two-sides.component';
import { ContentJobsLink03Component } from './components/content-jobs-link03/content-jobs-link03.component';
import { ProductsListComponent } from './views/products-list/products-list.component';
import { ProductComponent } from './views/product/product.component';
import { RelatedProductsComponent } from './components/related-products/related-products.component';
import { CategoryComponent } from './views/category/category.component';
import { HomeComponent } from './views/home/home.component';
import { ReportsComponent } from './views/reports/reports.component';
import { Careers01Component } from './views/careers01/careers01.component';
import { Careers02Component } from './views/careers02/careers02.component';
import { Careers03Component } from './views/careers03/careers03.component';
import { Careers04Component } from './views/careers04/careers04.component';
import { Careers05Component } from './views/careers05/careers05.component';
import { News01Component } from './views/news01/news01.component';
import { News02Component } from './views/news02/news02.component';

import { CovidPostComponent } from './views/covidPost/covidPost.component';


import { LegalComponent } from './views/legal/legal.component';
import { ModalComponent } from './components/modal/modal.component';
import { ApiService } from './services/api/api.service';
import { PrivacyComponent } from './views/privacy/privacy.component';
import { TermsComponent } from './views/terms/terms.component';
import { ContactProductsComponent } from './views/contact/contact-products/contact-products.component';
import { CovidContactComponent } from './views/covidPost/covid-contact/covid-contact.component';
import { ArticleComponentComponent } from './components/article-component/article-component.component';
import { BenefitComponent } from './components/benefit/benefit.component';
import { JobDetailComponent } from './components/job-detail/job-detail.component';
import { PrivacyPoliciesComponent } from './views/privacy-policies/privacy-policies.component';
import { PageNotFoundComponent } from './views/page-not-found/page-not-found.component';
import { PageComponent } from './views/page/page.component';
import { GapcalculatorComponent } from './components/gapcalculator-component/gapcalculator.component';
import { BlockVideoComponent } from './components/block-video/block-video.component';

import { HelperProvider } from './providers/helper.provider';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    AboutUsComponent,
    ContactComponent,
    AboutUs02Component,
    AboutUs03Component,
    AboutUs04Component,
    OurValuesComponent,
    GreatPlaceToWorkComponent,
    ProductsOfferComponent,
    Content01Component,
    Content02Component,
    Content03Component,
    ContentJobsLinkComponent,
    Content04Component,
    ContentJobsLink02Component,
    Content05Component,
    ArticlesTwoSidesComponent,
    ContentJobsLink03Component,
    ProductsListComponent,
    ProductComponent,
    RelatedProductsComponent,
    CategoryComponent,
    HomeComponent,
    LegalComponent,
    ReportsComponent,
    Careers01Component,
    Careers02Component,
    Careers03Component,
    Careers04Component,
    Careers05Component,
    News01Component,
    News02Component,
    CovidPostComponent,
    ModalComponent,
    PrivacyComponent,
    TermsComponent,
    ContactProductsComponent,
    CovidContactComponent,
    ArticleComponentComponent,
    BenefitComponent,
    JobDetailComponent,
    PrivacyPoliciesComponent,
    PageNotFoundComponent,
    PageComponent,
    GapcalculatorComponent,
    BlockVideoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    GoogleMapsModule,
    FormsModule,
    NgbModule
  ],
  providers: [
    ApiService,
    HelperProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
