import { Component, OnInit } from '@angular/core';
import { CareersService } from '../../services/careers/careers.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JobDetailComponent } from '../../components/job-detail/job-detail.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-careers03',
  templateUrl: './careers03.component.html',
  styleUrls: ['./careers03.component.scss']
})
export class Careers03Component implements OnInit
{

  public data;
  public jobs;
  public alljobs;
  public hideShowMoreLink = false;
  public loadJobs;


  public list;
  public competenciesList;
  public competenciesListItem1;
  public competenciesTechnicalText;
  public competenciesTechnical;
  public competenciesBehaviouralText;
  public competenciesBehavioural;
  public responsabilityList;
  public responsabilityListItem1;
  public responsabilityListItem2;
  public respAloneItem;

  constructor(
      private careersService: CareersService,
      private modalService: NgbModal,
      private meta: Meta,
      private titleService: Title
  ) { }

  ngOnInit()
  {
    this.careersService.getCareer('03').subscribe(careers => {
      this.data = (careers.length > 0) ? careers[0].acf : careers.acf;
      this.alljobs = JSON.parse(this.data.content.jobs_list);

      if (this.data.hasOwnProperty('meta_tag_title')) {
        this.titleService.setTitle(this.data.meta_tag_title.content ?? 'Career');
      }
      if (this.data.hasOwnProperty('meta_tag_description')) {
        this.meta.updateTag({ name: 'description', content: this.data.meta_tag_description.content });
      }

      this.jobs = this.alljobs.slice(0, 6);

      if (this.alljobs.length <= 6) {
        this.hideShowMoreLink = true;
      }

      this.getLoadJobs();
    });
  }


  getLoadJobs()
  {
    this.careersService.getJobDetail().subscribe(job => {
      const j = (job.length > 0) ? job[0] : job;
      this.loadJobs = JSON.parse(j.acf.all_jobs);

      // tslint:disable-next-line:prefer-for-of
      for(let i = 0; i < this.jobs.length; i++) {
        this.jobs[i].data = this.findJobBySlug(this.jobs[i].SLUG);

        const compList = this.jobs[i].data.description_role_competencies_list;
        this.jobs[i].data.competenciesTechnicalText = compList.technical.text;
        this.jobs[i].data.competenciesBehaviouralText = compList.behavioural_competencies.text;
        this.jobs[i].data.competenciesListItem1 = Object.keys(compList.list_item).map(e => compList.list_item[e]);
        this.jobs[i].data.competenciesTechnical = Object.keys(compList.technical).map(e => compList.technical[e]);
        this.jobs[i].data.competenciesBehavioural = Object.keys(compList.behavioural_competencies).map(e => compList.behavioural_competencies[e]);
        this.jobs[i].data.competenciesList = Object.keys(compList).map(e => compList[e]);

        this.jobs[i].data.competenciesTechnical.pop();
        this.jobs[i].data.competenciesBehavioural.pop();

        const respList = this.jobs[i].data.description_key_responsibilities_list;

        this.jobs[i].data.responsabilityListItem1 = Object.keys(respList.list_item_1).map(e => respList.list_item_1[e]).reverse();
        this.jobs[i].data.responsabilityListItem2 = Object.keys(respList.list_item_2).map(e => respList.list_item_2[e]).reverse();
        this.jobs[i].data.responsabilityList = Object.keys(respList).map((e, idx) => {
          if (e.indexOf('list_item') === -1) {
            return respList[e];
          }
        }).reverse();
      }
    });
  }


  findJobBySlug(slug)
  {
    if (this.loadJobs) {
      const jobInfo = this.loadJobs.find(jobObj  => {
        return jobObj.SLUG === slug;
      });

      return jobInfo ? jobInfo.ACF : false;
    }

    return false;
  }

  openJobDetails(slug, event)
  {
    event.preventDefault();
    document.getElementById('modal_job_' + slug).style.display = 'block';
  }

  closeJobDetails(slug, event)
  {
    event.preventDefault();
    document.getElementById('modal_job_' + slug).style.display = 'none';
  }

  showAll(event)
  {
    event.preventDefault();

    this.jobs = this.alljobs;
    this.hideShowMoreLink = true;
  }
}
