<footer id="footer" class="footer">
  <section class="footer__top">
    <div class="footer__top__legalText">
      <p>
        {{ data?.copyright_text.part_1 }}
      </p>
      <p>
        {{ data?.copyright_text.part_2 }}
      </p>
    </div>
      <nav class="footer__top__nav">
        <ul class="footer__top__nav__menu">
          <li>
            <a [routerLink]="['/home']" routerLinkActive="active" [innerHTML]="data?.header_menu_links?.link_home" title="Home" rel="noopener noreferrer"></a>
          </li>
          <li>
            <a [routerLink]="['/aboutus']" routerLinkActive="active" [innerHTML]="data?.header_menu_links?.link_about_us" title="About Us" rel="noopener noreferrer"></a>
          </li>
          <li>
            <a [routerLink]="['/news']" routerLinkActive="active" [innerHTML]="data?.header_menu_links?.link_corporate_news" title="Corporate News" rel="noopener noreferrer"></a>
          </li>
          <li>
            <a [routerLink]="['/products']" routerLinkActive="active" [innerHTML]="data?.header_menu_links?.link_products" title="Products" rel="noopener noreferrer"></a>
          </li>
          <li>
            <a [routerLink]="['/careershome']" routerLinkActive="active" [innerHTML]="data?.header_menu_links?.link_careers" title="Carrers" rel="noopener noreferrer"></a>
          </li>
          <li>
            <a [routerLink]="['/contact']" routerLinkActive="active" [innerHTML]="data?.header_menu_links?.link_contact" title="Contact" rel="noopener noreferrer"></a>
          </li>
        </ul>
      </nav>
  </section>
  <div class="footer__bottom">
    <a class="mainLogo" [routerLink]="['home']" title="Home" rel="noopener noreferrer">
      <img src="{{ data?.logo_santander }}" alt="Logo Santander">
    </a>
    <div class="footer__bottom__nav">
      <ul class="footer__bottom__nav__menu">
        <li>
          <a [routerLink]="['/termsandconditions']" [innerHTML]="data?.link_website_terms_conditions" title="Terms and Conditions" rel="noopener noreferrer"></a>
        </li>
        <li>
          <a [routerLink]="['/legal']" [innerHTML]="data?.link_legal" title="Legal" rel="noopener noreferrer"></a>
        </li>
        <li>
          <a [routerLink]="['/privacy-policy']" [innerHTML]="data?.link_privacy_policy" title="Privacy Policy" rel="noopener noreferrer"></a>
        </li>
        <li>
          <a [routerLink]="['/reports']" [innerHTML]="data?.link_public_disclosure" title="Disclosure" rel="noopener noreferrer"></a>
        </li>
        <li>
          <a [routerLink]="['/privacy-policies']" [innerHTML]="data?.link_privacy_policies" title="Privacy Policies" rel="noopener noreferrer"></a>
        </li>
        <li *ngIf="data?.link_cookies?.text">
          <a [href]="data?.link_cookies?.url" [innerHTML]="data?.link_cookies?.text" target="_blank" title="Cookies" rel="noopener noreferrer"></a>
        </li>
        <li *ngIf="data?.link_code_of_conduct_download?.text">
          <a [href]="data?.link_code_of_conduct_download?.url" target="_blank" [innerHTML]="data?.link_code_of_conduct_download?.text" title="Cookies" rel="noopener noreferrer"></a>
        </li>
      </ul>
    </div>
    <a class="socialLinks" href="https://www.linkedin.com/company/cnp-santander-insurance/" target="_blank" title="Go to Linkedin" rel="noopener noreferrer">
      <img src="{{ data?.logo_linkedin }}" alt="Logo Linkedin">
    </a>
  </div>
</footer>
