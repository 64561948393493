<section class="text-image">
  <div class="text-image__inner">
    <article class="text-image__content">
      <h2 class="text-image__content__title">
        {{ data?.title }}
      </h2>
      <div class="text-image__content__text">
        <p>
          <strong>{{ data?.text?.part_1['bold_part_1-1'] }}</strong>
          <!-- <span>&nbsp;</span> -->
          <span>{{ data?.text?.part_1['part_1-2'] }}</span>
        </p>
        <p>
          {{ data?.text?.part_2 }}
        </p>
        <p>
          <strong>{{ data?.text?.bold_part_3 }}</strong>
        </p>
      </div>
    </article>
    <div class="text-image__image">
      <img src="{{ data?.img }}" class="text-image__image__img" alt="Image">
    </div>
  </div>
</section>
