<section class="greatPalceToWork">
  <div class="greatPalceToWork__inner">
    <article [class]="(data?.great_place_img) ? 'greatPalceToWork__content' : 'greatPalceToWork__content_full'">
      <h3 class="greatPalceToWork__content__title">
        <span>{{ data?.title?.text_part_1 }}</span>
        <span>&nbsp;</span>
        <em class="red">{{ data?.title?.red_text_part_2 }}</em>
      </h3>
      <p class="greatPalceToWork__content__text">
        <span>{{ data?.text?.part_1 }}</span>
        <span>&nbsp;</span>
        <strong>{{ data?.text?.bold_black_text_part }}</strong>
        <span>&nbsp;</span>
        <span>{{ data?.text?.bold_black_text_part_copy }}</span>
      </p>
      <a [routerLink]="['/careers/careers05']" class="link" title="Careers" rel="noopener noreferrer">
        <span class="link__text">{{ data?.button_text }}</span>
      </a>
    </article>
    <div *ngIf="data?.great_place_img" class="greatPalceToWork__logo">
        <img src="{{ data?.great_place_img }}" class="greatPalceToWork__logo__img" alt="Logo Great Place to work">
    </div>
  </div>
</section>
