<section class="about-title">
  <div class="about-title__inner">
    <h1 class="about-title__title">
      <span>{{ data?.title?.part_1 }}</span>
      <span>&nbsp;</span>
      <strong>{{ data?.title?.bold_part_2 }}</strong>
    </h1>
    <article class="about-title__intro">
      <p>
        <span>{{ data?.subtitle?.part_1 }}</span>
        <em>
          {{ data?.subtitle?.red_part_2 }}
        </em>
        <span>{{ data?.subtitle?.part_3 }}</span>
      </p>
    </article>
  </div>
</section>
