<section *ngIf="current" class="news">

  <div *ngIf="current?.background_img" class="news__image"
       [ngStyle]="{'background-image': 'url(' + current?.background_img + ')'}">
  </div>

  <nav *ngIf="prevNew?.slug || nextNew?.slug" class="news__navigation">
    <div class="news__navigation__inner">
      <a [routerLink]="['/corporate-news/',  prevNew?.slug ]" class="news__navigation__prev" [ngClass]="{ disabled: prevNew === false }" rel="noopener noreferrer">
        <span class="news__navigation__prev__arrow"></span>
      </a>
      <a [routerLink]="['/corporate-news/',  nextNew?.slug ]" class="news__navigation__next" [ngClass]="{ disabled: nextNew === false }" rel="noopener noreferrer">
        <span class="news__navigation__next__arrow"></span>
      </a>
    </div>
  </nav>

  <article class="news__content">
    <div class="news__content__inner">
      <div *ngIf="current?.title" class="news__content__title" [innerHTML]="sanitizerHtml(current?.title)"></div>
      <div *ngIf="current?.wp_modified_date" class="news__content__date" [innerHTML]="sanitizerHtml(current?.wp_modified_date)"></div>
      <div *ngIf="current?.subtitle" class="news__content__intro" [innerHTML]="sanitizerHtml(current?.subtitle)"></div>
      <div *ngIf="current?.first_text" class="news__content__text" style="margin-bottom: 25px" [innerHTML]="sanitizerHtml(current?.first_text)"></div>

      <div *ngIf="current?.first_left_column || current?.first_right_column" class="text-2cols">
        <div class="text-2cols__inner">
          <div *ngIf="current?.first_left_column" class="text-2cols__col" [innerHTML]="sanitizerHtml(current?.first_left_column)"></div>
          <div *ngIf="current?.first_right_column" class="text-2cols__col" [innerHTML]="sanitizerHtml(current?.first_right_column)"></div>
        </div>
      </div>

      <div *ngIf="current?.quote?.hashtag || current?.quote?.text" class="quote">
        <div class="quote__inner">
          <div *ngIf="current?.quote?.hashtag" class="quote__hashtag" [innerHTML]="sanitizerHtml(current?.quote?.hashtag)"></div>
          <div *ngIf="current?.quote?.text" class="quote__text" [innerHTML]="sanitizerHtml(current?.quote?.text)"></div>
        </div>
      </div>

      <div *ngIf="current?.second_text" class="news__content__text">
        <img *ngIf="current?.second_text_img" src="{{ current?.second_text_img }}" class="w-100 mb-5" alt="News Image">
        <div [innerHTML]="sanitizerHtml(current?.second_text)"></div>
      </div>

      <div *ngIf="current?.second_left_column || current?.second_right_column" class="text-2cols">
        <div class="text-2cols__inner">
          <div *ngIf="current?.second_left_column" class="text-2cols__col" [innerHTML]="sanitizerHtml(current?.second_left_column)"></div>
          <div *ngIf="current?.second_right_column" class="text-2cols__col" [innerHTML]="sanitizerHtml(current?.second_right_column)"></div>
        </div>
      </div>
    </div>
  </article>
</section>
