<section class="text-cta text-cta--light-bg">
  <div class="text-cta__inner">
    <h2 class="text-cta__title">{{ data?.title }}</h2>
    <div class="text-cta__text">
      <p>
        {{ data?.subtitle }}
      </p>
    </div>
    <a [routerLink]="['/jobs']" class="btn btn--red" title="Jobs" rel="noopener noreferrer">
      <span>{{ data?.button_text }}</span>
      <img src="../assets/icons/right-white-icon.svg" class="btn__asset" alt="Icon Right">
    </a>
  </div>
</section>
