<section class="news">
  <div class="news__image" [ngStyle]="{'background-image': 'url(' + headerImg + ')'}"></div>

  <div class="news__selector">
    <span *ngIf="title" class="news__hero-title">{{ title }}</span>
    <p *ngIf="sub_title">{{ sub_title }}</p>
    <article *ngIf="id === 'en'">
      <label for="news__select" style="display: none">{{ title }}</label>
      <select id="news__select" name="news__select" class="news__select btn btn--white" (change)="onNavigate($event)">
        <option value="/covid19/en">Country List</option>
        <option value="/covid19/at">Austria</option>
        <option value="/covid19/be">Belgium</option>
        <option value="/covid19/dk">Denmark</option>
        <option value="/covid19/fi">Finland</option>
        <option value="/covid19/de">Germany</option>
        <option value="/covid19/it">Italy</option>
        <option value="/covid19/nl">Netherlands</option>
        <option value="/covid19/no">Norway</option>
        <option value="/covid19/pl">Poland</option>
        <option value="/covid19/pt">Portugal</option>
        <option value="/covid19/es">Spain</option>
        <option value="/covid19/se">Sweden</option>
      </select>

      <a *ngIf="button_text" class="btn btn--white" ng-reflect-router-link="/contact" href="/contact" title="Contact" rel="noopener noreferrer">
        <span class="link_test">{{ button_text }}</span>
      </a>
    </article>

    <article *ngIf="id !== 'en'">
      <a class="btn btn--white" ng-reflect-router-link="/covid19/en" href="/covid19/en" title="Covid 19 EN" rel="noopener noreferrer">
        <span class="link_test">{{ button_text }}</span>
      </a>
    </article>
  </div>

  <div class="news__navigation"></div>

  <article class="news__content">
    <div class="news__content__inner">
      <div *ngIf="content" class="news__content__text" [innerHTML]="content"></div>
      <section *ngIf="pdfDownload?.section_title || pdfDownload?.download_box?.title" class="mac-download">
        <div class="mac-download__inner">
          <h2 *ngIf="pdfDownload?.section_title" class="section-title">
            {{ pdfDownload?.section_title }}
          </h2>
          <article *ngIf="pdfDownload?.download_box?.title" class="mac-download__box">
            <h3 *ngIf="pdfDownload?.download_box?.title" class="mac-download__box__title">
              <img
                *ngIf="pdfDownload?.download_box?.img"
                [src]="pdfDownload?.download_box?.img"
                alt="Download Image"/>
              <span>&nbsp;</span>
              <img class="downloadIcon" src="../../../assets/icons/download-icon.png" alt="Icon doc"/>
              <span>{{ pdfDownload?.download_box?.title }}</span>
            </h3>
            <div class="mac-download__inputgroup">
              <div class="fp-dropdown">
                <button
                  (click)="openList($event)"
                  class="fp-dropdown__btn"
                  type="button"
                  name="button_covid_dropdown"
                >
                  <span class="fp-dropdown__btn__text">
                    <img
                      src="../../../assets/icons/file-icon.png"
                      alt="Icon doc"
                    />
                    {{
                      selectedFileDownload
                        ? selectedFileDownload?.name
                        : pdfDownload?.download_box?.title
                    }}
                  </span>
                  <img
                    src="../../../assets/icons/down-icon.png"
                    class="fp-dropdown__btn__asset"
                    alt="Icon down"
                  />
                </button>
                <ul class="fp-dropdown__list">
                  <li
                    *ngFor="
                      let item of [].constructor(
                        getLengthObjectKeys(pdfDownload?.download_box?.files)
                      );
                      let i = index
                    "
                  >
                    <button
                      *ngIf="
                        pdfDownload?.download_box?.files['file_' + (i + 1)]
                          ?.name
                      "
                      (click)="
                        selectOption(
                          $event,
                          pdfDownload?.download_box?.files['file_' + (i + 1)],
                          'selectedFileDownload'
                        )
                      "
                      class="fp-dropdown__opt"
                      type="button"
                      name="button_covid_select_file"
                    >
                      <img
                        src="../../../assets/icons/file-icon.png"
                        alt="Icon doc"
                      />
                      {{
                        pdfDownload?.download_box?.files["file_" + (i + 1)]
                          ?.name
                      }}
                    </button>
                  </li>
                </ul>
              </div>
              <button
                *ngIf="pdfDownload?.text_button"
                class="mac-download__button btn btn--blue"
                type="button"
                (click)="downloadFile('selectedFileDownload')"
                name="button_covid_download"
              >
                {{ pdfDownload?.text_button }}
              </button>
            </div>
          </article>
        </div>
      </section>
      <section *ngFor="let location of locations">
        <app-covid-contact
          [data]="location"
          (infoClosed)="closedWindowHandler($event)"
        ></app-covid-contact>
      </section>
    </div>
  </article>
</section>
