<section class="careers">
  <article class="big-title big-title__inner">
    <h4 class="big-title__inner__introtext">
      <span>{{ data?.header?.text?.part_1 }}</span>
      <span>&nbsp;</span>
      <strong>{{ data?.header?.text?.bold_part_2 }}</strong>
    </h4>
    <h1 class="big-title__inner__title">
      {{ data?.header?.title }}
    </h1>
    <a [routerLink]="['/jobs']" class="btn btn--red" title="Jobs" rel="noopener noreferrer">
      <!-- Contrast Failure ↓ -->
      <span>{{ data?.header?.red_button_text }}</span>
      <img src="../assets/icons/right-white-icon.svg" class="btn__asset" alt="Icon Right">
    </a>
  </article>

  <section class="distributor distributor--right-top distributor__inner">
    <div class="distributor__inner">
      <article class="distributor__content">
        <h2 class="distributor__content__title">{{ data?.content?.box_1?.text }}</h2>
        <a class="btn btn--grey" [routerLink]="['/careers/webelieveinyou']" title="Careers We believe in you" rel="noopener noreferrer">
          <span>{{ data?.content?.box_1?.link_text }}</span>
          <img class="btn__asset" src="../assets/icons/plus-grey-icon.png" alt="Icon Plus Grey" style="width: 36px; height: 35px;">
          <img class="btn__asset btn__asset--hover" src="../assets/icons/plus-white-icon.png" alt="Icon Plus White"style="width: 36px; height: 35px;">
        </a>
      </article>
      <div class="distributor__image">
          <img src="{{ data?.content?.box_1?.img }}" class="distributor__image__img" alt="Distributor Image">
      </div>
    </div>
  </section>
  
  <section class="distributor distributor--left-bottom">
    <div class="distributor__inner">
      <article class="distributor__content">
        <h2 class="distributor__content__title">{{ data?.content?.box_2?.text }}</h2>
        <a class="btn btn--grey" [routerLink]="['/careers/careers05']" title="Careers" rel="noopener noreferrer">
          <span>{{ data?.content?.box_2?.link_text }}</span>
          <img class="btn__asset" src="../assets/icons/plus-grey-icon.png" alt="Icon Plus Grey" style="width: 36px; height: 35px;">
          <img class="btn__asset btn__asset--hover" src="../assets/icons/plus-white-icon.png" alt="Icon Plus White" style="width: 36px; height: 35px;">
        </a>
      </article>
      <div class="distributor__image">
          <img src="{{ data?.content?.box_2?.img }}" class="distributor__image__img" alt="Distributor Image">
      </div>
    </div>
  </section>
  
  <section class="distributor distributor--right-bottom">
    <div class="distributor__inner">
      <article class="distributor__content">
        <h2 class="distributor__content__title">{{ data?.content?.box_3?.text }}</h2>
        <a class="btn btn--grey" [routerLink]="['/careers/workwithus']" title="Careers Work with Us" rel="noopener noreferrer">
          <span>{{ data?.content?.box_3?.link_text }}</span>
          <img class="btn__asset" src="../assets/icons/plus-grey-icon.png" alt="Icon Plus Grey" style="width: 36px; height: 35px;">
          <img class="btn__asset btn__asset--hover" src="../assets/icons/plus-white-icon.png" alt="Icon Plus White" style="width: 36px; height: 35px;">
        </a>
      </article>
      <div class="distributor__image">
          <img src="{{ data?.content?.box_3?.img }}" class="distributor__image__img" alt="Distributor Image">
      </div>
    </div>
  </section>

  <section class="text-cta" *ngIf="data && data.footer">
    <article class="text-cta__inner">
      <h2 class="text-cta__title">{{ data?.footer?.title }}</h2>
      <p class="text-cta__text">
        {{ data?.footer?.text }}
      </p>
      <!-- Contrast Failure ↓ -->
      <a [routerLink]="['/jobs']" class="btn btn--red" title="Jobs" rel="noopener noreferrer">
        <span>{{ data?.footer?.red_button_text }}</span>
        <img src="../assets/icons/right-white-icon.svg" class="btn__asset" alt="Icon Right">
      </a>
    </article>
  </section>
</section>
