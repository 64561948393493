<section class="careers" *ngIf="data && data.header">
  <div class="medium-title-cta">
    <div class="medium-title-cta__inner">
      <h1 class="medium-title-cta__title">
        <strong>{{ data?.header?.title }}</strong>
      </h1>
      <article class="medium-title-cta__content">
        <div class="medium-title-cta__content__text">
          <p>
            {{ data?.header?.subtitle }}
          </p>
          <p>
            {{ data?.header?.text_1 }}
          </p>
        </div>
        <div *ngIf="data?.header?.img" class="medium-title-cta__content__image">
          <img src="{{ data?.header?.img }}" class="medium-title-cta__content__image__img" alt="CTA Content Image">
        </div>
      </article>
    </div>
  </div>

  <article class="text-intro">
    <div class="text-intro__inner">
      <p>
        {{ data?.header?.text_2 }}
      </p>
    </div>
  </article>

  <article class="image-list">
    <img src="{{ data?.content?.img }}" class="image-list__img" alt="Image List">
    <div class="image-list__inner">
      <h2 class="image-list__title">
        {{ data.content.title }}
      </h2>
      <dl class="image-list__list">
        <dt class="image-list__list__term">{{ data?.content?.reason_box_1?.title }}</dt>
        <dd class="image-list__list__def">
          <span>{{ data?.content?.reason_box_1?.text?.part_1 }}</span>
          <br /><br />
          <span>{{ data?.content?.reason_box_1?.text?.part_2 }}</span>
          <br /><br />
          <span>{{ data?.content?.reason_box_1?.text?.part_3 }}</span>
        </dd>
      </dl>
      <dl class="image-list__list">
        <dt class="image-list__list__term">{{ data?.content?.reason_box_2?.title }}</dt>
        <dd class="image-list__list__def">
          <span>{{ data?.content?.reason_box_2?.text?.part_1 }}</span>
          <br /><br />
          <span>{{ data?.content?.reason_box_2?.text?.part_2 }}</span>
          <br /><br />
          <span>{{ data?.content?.reason_box_2?.text?.part_3 }}</span>
        </dd>
      </dl>
      <dl class="image-list__list">
        <dt class="image-list__list__term">{{ data?.content?.reason_box_3?.title }}</dt>
        <dd class="image-list__list__def">
          <span>{{ data?.content?.reason_box_3?.text?.part_1 }}</span>
          <br /><br />
          <span>{{ data?.content?.reason_box_3?.text?.part_2 }}</span>
          <br /><br />
          <span>{{ data?.content?.reason_box_3?.text?.part_3 }}</span>
        </dd>
      </dl>
    </div>
  </article>

  <article class="mail-contact" *ngIf="data && data.footer">
    <div class="mail-contact__inner">
      <h2 class="mail-contact__title">
        {{ data?.footer?.title }}
      </h2>
      <div class="mail-contact__text">
        <p>
          {{ data?.footer?.text?.part_1 }}
        </p>
        <p>
          {{ data?.footer?.text?.part_2 }}
        </p>
        <!-- Contrast Failure ↓ -->
        <a [routerLink]="['/jobs']" class="btn btn--red" title="Jobs" rel="noopener noreferrer">{{ data?.footer?.text?.red_email_part_3 }}</a>
      </div>
    </div>
  </article>

</section>
