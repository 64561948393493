import { Component, OnInit } from '@angular/core';
import { CareersService } from '../../services/careers/careers.service';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-careers04',
  templateUrl: './careers04.component.html',
  styleUrls: ['./careers04.component.scss']
})
export class Careers04Component implements OnInit
{

  public data;
  public benefits;

  constructor(
      private careersService: CareersService,
      private meta: Meta, private titleService: Title
  ) { }

  ngOnInit()
  {
    this.careersService.getCareer('04').subscribe(careers => {
      this.data = (careers.length > 0) ? careers[0].acf : careers.acf;
      if (this.data.hasOwnProperty('meta_tag_title')) {
        this.titleService.setTitle(this.data.meta_tag_title.content ?? 'Careers');
      }
      if (this.data.hasOwnProperty('meta_tag_description')) {
        this.meta.updateTag({ name: 'description', content: this.data.meta_tag_description.content });
      }
      const obj = this.data.content.benefits_part.benefits_list;
      this.benefits = Object.keys(obj).map(e => obj[e]);
    });
  }

}
