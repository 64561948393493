<section class="ourValues">
  <div class="ourValues__inner">
    <article class="ourValues__content">
      <h3 class="ourValues__content__title">{{ data?.title }}</h3>
      <p class="ourValues__content__text">
        <span>{{ data?.text?.part_1 }}</span>
        <span>&nbsp;</span>
        <strong>{{ data?.text?.bold_part_2 }}</strong>
        <span>&nbsp;</span>
        <span>{{ data?.text?.part_3 }}</span>
      </p>
      <section class="ourValues__content__wrap">
        <article class="ourValues__content__wrap__item">
          <div class="ourValues__content__wrap__item__content">
            <img src="{{ data?.values_list?.value_1?.img }}" class="ourValues__content__wrap__item__img" alt="Our Values Image">
            <p class="ourValues__content__wrap__item__text">{{ data?.values_list?.value_1?.title }}</p>
          </div>
        </article>
        <article class="ourValues__content__wrap__item">
          <div class="ourValues__content__wrap__item__content">
            <img src="{{ data?.values_list?.value_2?.img }}" class="ourValues__content__wrap__item__img" alt="Our Values Image">
            <p class="ourValues__content__wrap__item__text">{{ data?.values_list?.value_2?.title }}</p>
          </div>
        </article>
        <article class="ourValues__content__wrap__item">
          <div class="ourValues__content__wrap__item__content">
            <img src="{{ data?.values_list?.value_3?.img }}" class="ourValues__content__wrap__item__img" alt="Our Values Image">
            <p class="ourValues__content__wrap__item__text">{{ data?.values_list?.value_3?.title }}</p>
          </div>
        </article>
        <article class="ourValues__content__wrap__item">
          <div class="ourValues__content__wrap__item__content">
            <img src="{{ data?.values_list?.value_4?.img }}" class="ourValues__content__wrap__item__img" alt="Our Values Image">
            <p class="ourValues__content__wrap__item__text">{{ data?.values_list?.value_4?.title }}</p>
          </div>
        </article>
      </section>
        <section *ngIf="data?.brand_content?.visible" class="ourValues__content__brand_content">
            <div *ngIf="data?.brand_content?.title" class="ourValues__content__brand_content__title" [innerHTML]="data?.brand_content?.title"></div>
            <div *ngIf="data?.brand_content?.logo" class="ourValues__content__brand_content__image">
                <img [src]="data?.brand_content?.logo" alt="Image" />
            </div>
        </section>
      <div class="ourValues__content__text">
        <a [routerLink]="['/ourvalues']" class="link" title="Our Values" rel="noopener noreferrer">
          <span class="link__text">Continue reading</span>
        </a>
      </div>
    </article>
  </div>
</section>
