import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
import { PageService } from '../../services/page/page.service';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit
{
static x = 1;
  data: any;
  public selectedFileDownload: any;
  currentPage: String = "";
  constructor(
      public pageService: PageService,
      private route: ActivatedRoute,
      private meta: Meta,
      private titleService: Title
  ) { }

  ngOnInit()
  {
    this.route.params.subscribe(
        (params: any) => {
         this.getPage(params['id']);
        }
    );

    // sobreescribimos la url
    this.route.data.subscribe((params: any) => {
      if (params.hasOwnProperty('id')) {
        this.getPage(params.id);
      }
    });
  }

  getPage(page: string)
  {
    this.currentPage = page;
    if(page !=="cookies"){
      this.pageService.getPage(page).subscribe((p: any) => {
        this.data = (p.length > 0) ? p[0].acf : p.acf;
        
        if (this.data.hasOwnProperty('meta_tag_title')) {
          if (this.data.hasOwnProperty('meta_tag_title')) {
            this.titleService.setTitle(this.data.meta_tag_title.content ?? 'Page');
          }
          
          if (this.data.hasOwnProperty('meta_tag_description')) {
            this.meta.updateTag({ name: 'description', content: this.data.meta_tag_description.content });
          }
        }
      });
    }
    
  }

  openList($event)
  {
    const button = ( $event.target.tagName == 'BUTTON' ) ? $event.target : $event.target.parentNode;
    const list = button.nextSibling;
    if ( list.classList.contains('open') ) {
      list.classList.remove('open');
    } else {
      list.classList.add('open');
    }
  }

  downloadFile(variableSelected: string)
  {
    if (this[variableSelected]) {
      window.open(this[variableSelected].file, '_blank');
    }
  }

  getLengthObjectKeys(object: {})
  {
    if (typeof object === 'object' && object !== undefined && object !== null) {
      return Object.keys(object).length;
    }

    return 0;
  }

  selectOption($event, product: any, variableSelected: string)
  {
    const button = ( $event.target.tagName === 'BUTTON' ) ? $event.target : $event.target.parentNode;
    const list = button.parentNode.parentNode;
    const inputGroup = list.parentNode;
    const buttonSelected = inputGroup.children[0];
    buttonSelected.children[0].innerHTML = button.innerHTML;
    list.classList.remove('open');

    this[variableSelected] = product;
  }

}
