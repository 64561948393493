<section class="text-cta text-cta--image text-cta--light-bg" [ngStyle]="{'background-image': 'url(' + data?.background_img + ')'}">
  <div class="text-cta__inner">
    <h2 class="text-cta__title">
      <span>{{ data?.title?.part_1 }}</span>
      <span>&nbsp;</span>
      <strong>{{ data?.title?.bold_part_2 }}</strong>
      <span>&nbsp;</span>
      <span>{{ data?.title?.part_3 }}</span>
    </h2>
    <div class="text-cta__text">
      <p>
        {{ data?.text }}
      </p>
    </div>
    <a [routerLink]="['/jobs']" class="btn btn--red" title="Jobs" rel="noopener noreferrer">
      <span>{{ data?.button_text }}</span>
      <img src="../assets/icons/right-white-icon.svg" class="btn__asset" alt="Icon Right">
    </a>
  </div>
</section>
