<section class="productsOffer">
  <div class="productsOffer__inner">
    <article class="productsOffer__content">
      <h3 class="productsOffer__content__title">
        <span>{{ data?.title }}</span>
        <span>&nbsp;</span>
        <p class="backgroundTitle">Products</p>
      </h3>
      <p class="productsOffer__content__text">{{ data?.text }}</p>
      <a class="btn btn--light" [routerLink]="['/products']" title="{{ data?.button_text }}" rel="noopener noreferrer">{{ data?.button_text }}</a>
    </article>
  </div>
</section>
