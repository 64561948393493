<section class="about-title">
  <h1 class="about-title__inner about-title__title">
    <span>{{ data?.title?.part_1 }}</span>
    <span>&nbsp;</span>
    <strong>{{ data?.title?.bold_part_2 }}</strong>
  </h1>
</section>
<section class="text-2cols">
  <div class="text-2cols__inner">
    <article class="text-2cols__col">
      <p>
        <strong><em>{{ data?.subtitle?.left_part?.red_part_1 }}</em></strong>
        <span>&nbsp;</span>
        <span>{{ data?.subtitle?.left_part?.part_2 }}</span>
      </p>
    </article>
    <article class="text-2cols__col">
      <p>{{ data?.subtitle?.right_part }}</p>
    </article>
  </div>
</section>
