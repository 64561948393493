import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-related-products',
  templateUrl: './related-products.component.html',
  styleUrls: ['./related-products.component.scss']
})
export class RelatedProductsComponent implements OnInit, OnChanges
{

  @Input() slug;
  @Input() data;
  productList;

  constructor() { }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges)
  {
    if (changes.data && this.data && this.data.hasOwnProperty('products_title_list')) {
      const productList = JSON.parse(this.data.products_title_list);
      this.productList = productList.filter(_ => _.SLUG !== this.slug);
      this.productList = this.productList.filter((item, index) => index < 2);
    }
  }
}
