import { Injectable } from '@angular/core';
import {ApiService} from '../api/api.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductService
{

  constructor(private api: ApiService) {}

  getProducts(page = '', extra: string = ''): Observable<any>
  {
    return this.api.get('md.php?page=' + page + extra);
  }

}
