<section class="careers" *ngIf="data && data.content">
  <article class="medium-title">
    <h1 class="medium-title__inner medium-title__title">{{ data?.content?.title }}</h1>
  </article>

  <section class="text-2cols">
    <div class="text-2cols__inner">
      <article class="text-2cols__col">
        <p>
          {{ data?.content?.text?.left }}
        </p>
      </article>
      <article class="text-2cols__col">
        <p>
          {{ data?.content?.text?.right }}
        </p>
      </article>
    </div>
    <div class="text-2cols__inner">
      <article class="text-2cols__col">
        <a [routerLink]="['/jobs']" class="link" title="Jobs" rel="noopener noreferrer">
          <span class="link__text">{{ data?.content?.link_text }}</span>
        </a>
      </article>
    </div>
  </section>

  <section class="text-image">
    <div class="text-image__inner">
      <article class="text-image__content">
        <h2 class="text-image__content__title">
            <span>{{ data?.content?.we_believe_in_box_1?.title?.part_1 }} </span>
          <span>&nbsp;</span>
          <strong>{{ data?.content?.we_believe_in_box_1?.title?.bold_part_2 }}</strong>
        </h2>
        <div class="text-image__content__text">
          <p>
            {{ data?.content?.we_believe_in_box_1?.text?.part_1 }}
          </p>
          <p>
            {{ data?.content?.we_believe_in_box_1?.text?.part_2 }}
          </p>
        </div>
      </article>
      <div class="text-image__image">
        <img src="{{ data?.content?.we_believe_in_box_1?.img }}" class="text-image__image__img" alt="We believe Image" >
      </div>
    </div>
  </section>

  <section class="text-image text-image--reverse">
    <div class="text-image__inner">
      <article class="text-image__content">
        <h2 class="text-image__content__title">
          <span>{{ data?.content?.we_believe_in_box_2?.title?.part_1 }} </span>
          <span>&nbsp;</span>
          <strong>{{ data?.content?.we_believe_in_box_2?.title?.bold_part_2 }}</strong>
        </h2>
        <div class="text-image__content__text">
          <p>
            {{ data?.content?.we_believe_in_box_2?.text?.part_1 }}
          </p>
          <p>
            {{ data?.content?.we_believe_in_box_2?.text?.part_2 }}
          </p>
        </div>
      </article>
      <div class="text-image__image">
        <img src="{{ data?.content?.we_believe_in_box_2?.img }}" class="text-image__image__img" alt="We believe Image" >
      </div>
    </div>
  </section>

  <section class="text-image">
    <div class="text-image__inner">
      <article class="text-image__content">
        <h2 class="text-image__content__title">
          <span>{{ data?.content?.we_believe_in_box_3?.title?.part_1 }} </span>
          <span>&nbsp;</span>
          <strong>{{ data?.content?.we_believe_in_box_3?.title?.bold_part_2 }}</strong>
        </h2>
        <div class="text-image__content__text">
          <p>
            {{ data?.content?.we_believe_in_box_3?.text?.part_1 }}
          </p>
          <p>
            {{ data?.content?.we_believe_in_box_3?.text?.part_2 }}
          </p>
        </div>
      </article>
      <div class="text-image__image">
        <img src="{{ data?.content?.we_believe_in_box_3?.img }}" class="text-image__image__img" alt="We believe Image" >
      </div>
    </div>
  </section>

  <section class="lead-text">
    <div class="lead-text__inner">
      <p>
          <span>{{ data?.content?.bottom_text?.part_1 }} </span>
        <span>&nbsp;</span>
        <strong>{{ data?.content?.bottom_text?.bold_part_2 }}</strong>
      </p>
      <p>
        {{ data?.content?.bottom_text?.part_3 }}
      </p>
    </div>
  </section>

  <section class="mail-contact">
    <div class="mail-contact__inner">
      <h2 class="mail-contact__title">
        {{ data?.footer?.text?.bold_part_1 }}
      </h2>
      <div class="mail-contact__text">
        <p>
            {{ data?.footer?.text?.part_2 }}
        </p>
        <p>
          {{ data?.footer?.text?.part_3 }}
        </p>
        <!-- Contrast Failure ↓ -->
        <a [routerLink]="['/jobs']" class="btn btn--red" title="Jobs" rel="noopener noreferrer">{{ data?.footer?.text?.red_email_part_4 }}</a>
      </div>
    </div>
  </section>

</section>
