<section class="about-title">
  <div class="about-title__inner">
    <h1 class="about-title__title"><strong>{{ data?.header?.title }}</strong></h1>
    <section class="about-title__intro about-title__intro--two-cols">
      <article class="about-title__intro__col">
        <p>
          <span>{{ data?.header?.subtitle?.part_1 }}</span>
          <span>&nbsp;</span>
          <em>
            {{ data?.header?.subtitle?.red_part_2 }}
          </em>
          <span>&nbsp;</span>
          <span>{{ data?.header?.subtitle?.part_3 }}</span>
        </p>
      </article>
      <article class="about-title__intro__col">
        <div class="about-title__intro__icons">
          <section class="about-title__intro__icons__icon">
            <img src="{{ data?.header?.values_list?.value_1?.img }}" class="about-title__intro__icons__icon__img" alt="Intro Icons">
            <div class="about-title__intro__icons__icon__text">
              <p class="iconText">{{ data?.header?.values_list?.value_1?.text }}</p>
            </div>
          </section>
          <section class="about-title__intro__icons__icon">
            <img src="{{ data?.header?.values_list?.value_2?.img }}" class="about-title__intro__icons__icon__img" alt="Intro Icons">
            <div class="about-title__intro__icons__icon__text">
              <p class="iconText">{{ data?.header?.values_list?.value_2?.text }}</p>
            </div>
          </section>
          <section class="about-title__intro__icons__icon">
            <img src="{{ data?.header?.values_list?.value_3?.img }}" class="about-title__intro__icons__icon__img" alt="Intro Icons">
            <div class="about-title__intro__icons__icon__text">
              <p class="iconText">{{ data?.header?.values_list?.value_3?.text }}</p>
            </div>
          </section>
          <section class="about-title__intro__icons__icon">
            <img src="{{ data?.header?.values_list?.value_4?.img }}" class="about-title__intro__icons__icon__img" alt="Intro Icons">
            <div class="about-title__intro__icons__icon__text">
              <p class="iconText">{{ data?.header?.values_list?.value_4?.text }}</p>
            </div>
          </section>
        </div>
      </article>
    </section>
  </div>
</section>

<section class="text-2cols">
  <div class="text-2cols__inner">
    <article class="text-2cols__col">
      <p>
        <span>{{ data?.content_box?.left_text?.part_1['part_1-1'] }}</span>
        <span>&nbsp;</span>
        <strong>{{ data?.content_box?.left_text?.part_1['bold_part_1-2'] }}</strong>
        <span>&nbsp;</span>
        <span>{{ data?.content_box?.left_text?.part_1['part_1-3'] }}</span>
      </p>
      <p>{{ data?.content_box?.left_text?.part_2 }}</p>
    </article>
    <article class="text-2cols__col">
      <p>{{ data?.content_box?.right_text?.part_1 }}</p>
      <p>
        <span>{{ data?.content_box?.right_text?.part_2['part_2-1'] }}</span>
        <span>&nbsp;</span>
        <strong>{{ data?.content_box?.right_text?.part_2['bold_part_2-2'] }}</strong>
        <span>&nbsp;</span>
        <a [routerLink]="['/corporateculture']" class="link" title="Corporate Culture" rel="noopener noreferrer">
          <span class="link__text">
            {{ data?.content_box?.right_text?.part_2['red_bold_part_2-3'] }}
          </span>
        </a>
      </p>
    </article>
  </div>
</section>
