import { Component, OnInit } from '@angular/core';
import { NewsService } from '../../services/news/news.service';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-news01',
  templateUrl: './news01.component.html',
  styleUrls: ['./news01.component.scss'],
})
export class News01Component implements OnInit {
  public newsList;
  public allNews;
  public hideShowMoreLink = false;

  constructor(
    private newsService: NewsService,
    public domSanitizer: DomSanitizer,
    private meta: Meta,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.newsService.getNews().subscribe((news: any) => {
      this.allNews = news;
      this.newsList = this.allNews.slice(0, 4);

      this.titleService.setTitle('News');

      if (this.allNews && this.allNews.length <= 4) {
        this.hideShowMoreLink = true;
      }
    });
  }

  showAll(event)
  {
    event.preventDefault();
    this.newsList = this.allNews;
    this.hideShowMoreLink = true;

    document.dispatchEvent(new CustomEvent('refresh-reach-deck', {
      bubbles: true,
      detail: true
    }));
  }

  sanitizerHtml(html: any)
  {
    return this.domSanitizer.bypassSecurityTrustHtml(html);
  }
}
