import {Component, OnInit, Input, OnChanges, OnDestroy, AfterViewInit} from '@angular/core';
import { HelperProvider } from '../../providers/helper.provider';
import { DomSanitizer } from '@angular/platform-browser';

declare var dataLayer;

@Component({
  selector: 'app-block-video',
  templateUrl: './block-video.component.html',
  styleUrls: ['./block-video.component.scss']
})
export class BlockVideoComponent implements OnDestroy, AfterViewInit {

  @Input() data;

  vimeoUrlVideo: any;
  play: boolean = false;
  player: any;

  constructor(
      public helper: HelperProvider,
      public domSanitizer: DomSanitizer
  ) { }

  ngAfterViewInit()
  {
    setTimeout(() => {
      if (this.data) {
        this.vimeo();
      }
    }, 1000);
  }

  ngOnDestroy()
  {
    if (this.player) {
      this.player.destroy();
      this.player = null;
    }

    if (typeof dataLayer !== 'undefined') {
      dataLayer.push({
        event: 'video_player_dispose',
        videoUrl: this.vimeoUrlVideo
      });
    }
  }

  vimeo()
  {
    const vimeoId = this.helper.getVimeoIdFromUrl(this.data.content.url_video || this.data.content.iframe_video);
    if (this.data.show_block_video && vimeoId) {
      this.vimeoUrlVideo = this.domSanitizer.bypassSecurityTrustResourceUrl('https://player.vimeo.com/video/' + vimeoId + '?dnt=1');
      this.vimeoUrlVideo = this.vimeoUrlVideo.changingThisBreaksApplicationSecurity;
      
      const vimeo = document.getElementById('vimeo');

      if (vimeo) {
        vimeo.remove();
      }

      const iframe = document.createElement('iframe');
      iframe.src = this.vimeoUrlVideo;
      iframe.setAttribute('src', this.vimeoUrlVideo);
      iframe.setAttribute('id', 'vimeo');
      iframe.setAttribute('title', this.data.content.text.intro_1 ?? '');
      iframe.setAttribute('frameborder', '0');
      iframe.setAttribute('webkitallowfullscreen', '');
      iframe.setAttribute('mozallowfullscreen', '');
      iframe.setAttribute('allowfullscreen', '');
      iframe.setAttribute('allow', 'autoplay; encrypted-media');
      iframe.setAttribute('style', 'position: absolute;top: 0; left: 0;width: 100%;height: 100%;');

      const videoContent = document.getElementById('video_content');
      videoContent.appendChild(iframe);

      setTimeout(() => {

        if (vimeo) {
          this.player = this.helper.loadVimeo(vimeo, vimeoId);
          if (this.player && typeof dataLayer !== 'undefined') {
            this.player.on('play', () => {
              if (!this.play) {
                this.play = true;
                dataLayer.push({
                  event: 'video_player_firstplay',
                  videoUrl: this.vimeoUrlVideo
                });
              } else {
                dataLayer.push({
                  event: 'video_player_play',
                  videoUrl: this.vimeoUrlVideo
                });
              }
            });

            this.player.on('pause', () => {
              dataLayer.push({
                event: 'video_player_pause',
                videoUrl: this.vimeoUrlVideo
              });
            });

            this.player.on('ended', () => {
              dataLayer.push({
                event: 'video_player_ended',
                videoUrl: this.vimeoUrlVideo
              });
            });
          }
        }
      }, 1000);
    }
  }

}
