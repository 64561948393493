<section class="legal" *ngIf="data">
  <header class="legal__heading">
    <h1 class="legal__heading__title legal__heading__inner">{{ data?.header?.title }}</h1>
  </header>

  <article class="legal__content">
    <p class="legal__content__text">
      {{ data?.content?.text_1?.part_1 }}
    </p>
    <p class="legal__content__text">
      {{ data?.content?.text_1?.part_2 }}
    </p>
    <p class="legal__content__text">
      {{ data?.content?.text_1?.part_3 }}
    </p>
    <p class="legal__content__text">
      {{ data?.content?.text_1?.part_4 }}
    </p>
    
    <h2 class="legal__content__title">{{ data?.content?.text_2?.title }}</h2>
    <p class="legal__content__text">
      {{ data?.content?.text_2?.part_1 }}
    </p>
    <p class="legal__content__text">
      {{ data?.content?.text_2?.part_2 }}
    </p>
    
    <h2 class="legal__content__title">{{ data?.content?.text_3?.title }}</h2>
    <p class="legal__content__text">
      {{ data?.content?.text_3?.part_1 }}
    </p>
    <p class="legal__content__text">
      {{ data?.content?.text_3?.part_2 }}
    </p>

    <ul class="legal__content__text">
      <li *ngFor="let item of list3">{{ item?.text }}</li>
    </ul>
    
    <h2 class="legal__content__title">{{ data?.content?.text_4?.title }}</h2>
    <p class="legal__content__text">
      {{ data?.content?.text_4?.part_1 }}
    </p>
    <p class="legal__content__text">
      {{ data?.content?.text_4?.part_2 }}
    </p>

    <ul class="legal__content__text">
      <li *ngFor="let item of list41">{{ item?.text }}</li>
    </ul>
    <p class="legal__content__text">
      {{ data?.content?.text_4?.part_3 }}
    </p>
    <p class="legal__content__text">
      {{ data?.content?.text_4?.part_4 }}
    </p>
    <p class="legal__content__text">
      {{ data?.content?.text_4?.part_5 }}
    </p>
    <ul class="legal__content__text">
      <li *ngFor="let item of list42">{{ item?.text }}</li>
    </ul>
    <p class="legal__content__text">
      {{ data?.content?.text_4?.part_6 }}
    </p>
    <p class="legal__content__text">
      {{ data?.content?.text_4?.part_7 }}
    </p>
    
    <h2 class="legal__content__title">{{ data?.content?.text_5?.title }}</h2>
    <p class="legal__content__text">
      {{ data?.content?.text_5?.part_1 }}
    </p>
    
    <h2 class="legal__content__title">{{ data?.content?.text_6?.title }}</h2>
    <p class="legal__content__text">
      {{ data?.content?.text_6?.part_1 }}
    </p>
    
    <h2 class="legal__content__title">{{ data?.content?.text_7?.title }}</h2>
    <p class="legal__content__text">
      {{ data?.content?.text_7?.part_1 }}
    </p>
    
    <h2 class="legal__content__title">{{ data?.content?.text_8?.title }}</h2>
    <p class="legal__content__text">
      {{ data?.content?.text_8?.part_1 }}
    </p>
    <p class="legal__content__text">
      {{ data?.content?.text_8?.part_2 }}
    </p>
    <p class="legal__content__text">
      {{ data?.content?.text_8?.part_3 }}
    </p>
    <p class="legal__content__text">
      {{ data?.content?.text_8?.part_4 }}
    </p>
    
    <h2 class="legal__content__title">{{ data?.content?.text_9?.title }}</h2>
    <p class="legal__content__text">
      {{ data?.content?.text_9?.part_1 }}
    </p>
    
    <h2 class="legal__content__title">{{ data?.content?.text_10?.title }}</h2>
    <p class="legal__content__text">
      {{ data?.content?.text_10?.part_1 }}
    </p>
    <ul class="legal__content__text">
      <li *ngFor="let item of list10">{{ item?.text }}</li>
    </ul>
    <p class="legal__content__text">
      {{ data?.content?.text_10?.part_2 }}
    </p>
    
    <h2 class="legal__content__title">{{ data?.content?.text_11?.title }}</h2>
    <p class="legal__content__text">
      {{ data?.content?.text_11?.part_1 }}
    </p>
    <p class="legal__content__text">
      {{ data?.content?.text_11?.part_2 }}
    </p>
    <p class="legal__content__text">
      {{ data?.content?.text_11?.part_3 }}
    </p>
    <p class="legal__content__text">
      {{ data?.content?.text_11?.part_4 }}
    </p>
    <p class="legal__content__text">
      {{ data?.content?.text_11?.part_5 }}
    </p>
    
    <h2 class="legal__content__title">{{ data?.content?.text_12?.title }}</h2>
    <p class="legal__content__text">
      {{ data?.content?.text_12?.part_1 }}
    </p>
    
    <h2 class="legal__content__title">{{ data?.content?.text_13?.title }}</h2>
    <p class="legal__content__text">
      {{ data?.content?.text_13?.part_1 }}
    </p>
    
    <h2 class="legal__content__title">{{ data?.content?.text_14?.title }}</h2>
    <p class="legal__content__text">
      {{ data?.content?.text_14?.part_1 }}
    </p>
    
    <h2 class="legal__content__title">{{ data?.content?.text_15?.title }}</h2>
    <p class="legal__content__text">
      {{ data?.content?.text_15?.part_1 }}
    </p>
    <ul class="legal__content__text">
      <li *ngFor="let item of list15">{{ item?.text }}</li>
    </ul>
    <p class="legal__content__text">
      {{ data?.content?.text_15?.part_2 }}
    </p>
    
    <h2 class="legal__content__title">{{ data?.content?.text_16?.title }}</h2>
    <p class="legal__content__text">
      <span>{{ data?.content?.text_16?.part_1?.part_1_1 }}</span>
      <span>&nbsp;</span>
      <a href="mailto:{{ data?.content?.text_16?.part_1?.part_1_2_red_email }}" class="link" title="Send Email" rel="noopener noreferrer">
        <span class="link__text">{{ data?.content?.text_16?.part_1?.part_1_2_red_email }}</span>
      </a>
      <span>&nbsp;</span>
      <span>{{ data?.content?.text_16?.part_1?.part_1_3 }}</span>
    </p>
    <p class="legal__content__text">
      {{ data?.content?.text_16?.part_2 }}
    </p>
    <p class="legal__content__text">
      {{ data?.content?.text_16?.part_3 }}
    </p>
    <p class="legal__content__text">
      {{ data?.content?.text_16?.part_4 }}
    </p>
    <p class="legal__content__text">
      {{ data?.content?.text_16?.part_5 }}
    </p>
    <p class="legal__content__text">
      <span>{{ data?.content?.text_16?.part_6?.part_6_1 }}</span>
      <span>&nbsp;</span>
      <a href="mailto:{{ data?.content?.text_16?.part_6?.part_6_2_red_email }}" class="link" title="Send Email" rel="noopener noreferrer">
        <span class="link__text">{{ data?.content?.text_16?.part_6?.part_6_2_red_email }}</span>
      </a>
    </p>

    <h2 class="legal__content__title">{{ data?.content?.text_17?.title }}</h2>
    <p class="legal__content__text">
      <span>{{ data?.content?.text_17?.part_1?.part_1_1 }}</span>
      <span>&nbsp;</span>
      <a href="mailto:{{ data?.content?.text_17?.part_1?.part_1_2_red_email }}" class="link" title="Send Email" rel="noopener noreferrer">
        <span class="link__text">{{ data?.content?.text_17?.part_1?.part_1_2_red_email }}</span>
      </a>
    </p>

    <h2 class="legal__content__title">{{ data?.content?.text_18?.title }}</h2>
    <p class="legal__content__text">
      <span>{{ data?.content?.text_18?.part_1?.part_1_1 }}</span>
      <span>&nbsp;</span>
      <a href="mailto:{{ data?.content?.text_18?.part_1?.part_1_2_red_email }}" class="link" title="Send Email" rel="noopener noreferrer">
        <span class="link__text">{{ data?.content?.text_18?.part_1?.part_1_2_red_email }}</span>
      </a>
    </p>
    <p class="legal__content__text">
      {{ data?.content?.text_18?.part_2 }}
    </p>
    
    <h2 class="legal__content__title">{{ data?.content?.text_19?.title }}</h2>
    <p class="legal__content__text">
      {{ data?.content?.text_19?.part_1 }}
    </p>
    
    <h2 class="legal__content__title">{{ data?.content?.text_20?.title }}</h2>
    <p class="legal__content__text">
      {{ data?.content?.text_20?.part_1 }}
    </p>
    <p class="legal__content__text">
      {{ data?.content?.text_20?.part_2 }}
    </p>
  </article>
</section>
